import request from '@/request'
export const empLogin = (data = {}) => {
    return request({
      url: '/employee/login',
      method: 'post',
      data
    })
}
export const getEmployeeList = (data = {}) => {
  return request({
    url: '/employee/get_employee_list',
    method: 'get',
    params: data
  })
}
export const getDepartmentList = (data = {}) => {
  return request({
    url: '/employee/get_department_list',
    method: 'get',
    params: data
  })
}