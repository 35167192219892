<template>
    <div class="login">
        <a-spin :spinning="spinning">
            <div class="login_title">Log in</div>
            <div class="login_form">
                <a-form ref="form" :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-item label="Username">
                       <a-input v-model:value="formData.username" placeholder="Username" />
                    </a-form-item>
                    <a-form-item label="Password">
                       <a-input v-model:value="formData.password" placeholder="Password" type="password" />
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 18, offset: 6 }">
                        <a-radio-group v-model:value="formData.login_type">
                            <a-radio :value="1">Administrator</a-radio>
                            <a-radio :value="2">Employee</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 18, offset: 6 }">
                        <a-button block type="primary" class="primary btn" @click="login">Login</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </a-spin>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { adminLogin } from '@/apis/admin'
import { empLogin } from '@/apis/employee/employee'
import storage from '@/utils/storage'
export default {
    name: 'Login',
    data(){
        return {
            formData: {
                username: '',
                password: '',
                login_type: 1
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            spinning: false
        }
    },
    methods: {
        /**
         * 登录
         */
        async login(){
            const username = this.formData.username
            const password = this.formData.password
            if(username == '') return message.error('Please input username')
            if(password == '') return message.error('Please input password')
            this.spinning = true
            let res, path
            if(this.formData.login_type == 1){
                res = await adminLogin(this.formData)
                path = '/manage/admin'
            }
            if(this.formData.login_type == 2){
                res = await empLogin(this.formData)
                path = '/manage/employees'
            }
            this.spinning = false
            if(res){
                storage.setLocalData('token', res.token)
                storage.setLocalData('username', res.username)
                storage.setLocalData('emp_id', res.emp_id)
                storage.setLocalData('dep_class', res.dep_class)
                storage.setLocalData('actions', res.actions ? JSON.stringify(res.actions) : [])
                this.$router.push({path})
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
.login
    width 450px
    height 330px
    position fixed
    top 50%
    left 50%
    margin-top -165px
    margin-left -225px
    z-index 100
    padding 20px 40px
    box-shadow 0 0 10px 0 rgba(0,0,0,.05)
    background #fff
    .login_title 
        font-size 26px
        margin-bottom 20px
</style>