import request from '@/request'


export const adminLogin = (data = {}) => {
    return request({
      url: '/admin/login',
      method: 'post',
      data
    })
}

export const getAdminList = (data = {}) => {
  return request({
    url: '/admin/get_admin_list',
    method: 'get',
    params: data
  })
}

export const createAdmin = (data = {}) => {
  return request({
    url: '/admin/create_admin',
    method: 'post',
    data
  })
}
export const updateAdmin = (data = {}) => {
  return request({
    url: '/admin/update_admin',
    method: 'post',
    data
  })
}

export const removeAdmin = (data = {}) => {
  return request({
    url: '/admin/remove_admin',
    method: 'post',
    data
  })
}
